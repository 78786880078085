<template>
    <Navbar :user="user" />
    <section class="courses-details-area pt-70 pb-70" v-if="!isLoading">
      <div class="container">
        <div class="courses-details-header shadow-lg p-3 mb-5 bg-body rounded">
          <div class="row align-items-center">
            <div class="col-md-4">
              <div  class="courses-image">
                <div v-if="course.cover_photo">
                    <img v-bind:src="'https://apitraining.vipawaworks.com/' + course.cover_photo" class="image" alt="">
                </div>
                <div v-else>
                  <img src="@/assets/img/courses/1.jpg" class="image" alt="image" />
                </div>
              </div>
            </div>
            <div class="col-md-4 text-start">
              <div class="courses-title">
                <h2>{{ course.title }}</h2>
              </div>
              <div class="courses-price">
                <div class=" text-start">
                  <button class="default-btn" @click="learnCourse(course.id)" v-if="subscriptionStatus == 0">
                    <span class="label">Learn This Course</span>
                  </button>
                  <button  class="default-btn" @click="continueLearning(course.id)" v-else>
                    <span class="label">Continue Learning</span>
                  </button>
                  <!-- <router-link class="default-btn" :to="{ name: 'CourseLearn', params: {id: course.id}}" v-else>
                    <span class="label">Continue Learning</span>
                  </router-link> -->
                </div>
              </div>
            </div>
            <div class="col-md-4"> 
              <div class="courses-meta">
                <ul>
                  <li>
                    <svg height="30" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 19a2 2 0 01-2-2V7a2 2 0 012-2h4l2 2h4a2 2 0 012 2v1M5 19h14a2 2 0 002-2v-5a2 2 0 00-2-2H9a2 2 0 00-2 2v5a2 2 0 01-2 2z"></path></svg>
                    <span>Category</span>
                    <p class="text-dark">{{ course.category_name }}</p>
                  </li>-
                  <li>
                    <svg height="30"  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"></path></svg>
                    <span>Subject</span>
                    <p class="text-dark">{{ course.subject_name }}</p>
                  </li>
                </ul>
              </div>
              
            </div>
          </div>
        </div>
        <div class="courses-details-desc text-start">
          <tabs :options="{ defaultTabHash: 'descriptionTab' }" nav-item-class="nav-item" nav-class="nav nav-tabs justify-content-center" nav-item-link-class="nav-link btn" nav-item-link-active-class="active section-title">
            <tab id="descriptionTab" name="Course Description">
              <div class="pt-4">
                <h3>Description</h3>
                <span v-html="course.description"></span>
              </div>  
            </tab>
            <tab id="RequirementsTab" name="Course Requirements">
              <div class="pt-4">
                <h3>Requirements</h3>
                <span v-html="course.course_requirements"></span> 
              </div>
            </tab>
            <tab id="LearnTab" name="What You will Learn">
              <div class="pt-4">
                <h3>What you'll learn</h3>
                <div class="why-you-learn">
                  <span v-html="course.what_will_learn"></span> 
                </div>
              </div>
            </tab>
            <tab id="ContentTab" name="Content">
              <div class="pt-4">
                <h3>All Content</h3>
                <div class="why-you-learn">
                  <Accordion :course_content="course_content"/>
                  <div class="courses-list">
                      <div v-for="test in tests" :key="test.id">
                        <h6>
                          <router-link :to="{ name: 'TestCouresDetails', params: {id: course.id}}">
                          {{ test.title }}
                          </router-link>
                        </h6>
                      </div>
                  </div>
                </div>
              </div>
            </tab>
          </tabs>
        </div>
      </div>
    </section>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Loading from '@/components/Loading.vue'
import Accordion from '@/components/chapter/Accordion.vue'
import axios from 'axios'
import moment from 'moment'
export default {
 components: { Navbar,Loading,Accordion },
  data(){
    return {
      isLoading: true,
      subscriptionStatus: 0,
      rating: 4.3,
      course: {},
      tests:{},
      user:{},
      course_content:{},
      token: localStorage.getItem('user_data'),
    }
  },
  methods:{
    continueLearning(id){
      axios.post('https://apitraining.vipawaworks.com/api/lesson/get_last_lesson_learned/' + id).then(response => {
        this.lesson = response.data
        this.$router.push({ name: 'CourseLesson', params: {id: this.lesson.lesson_id} })
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    learnCourse(id){
      axios.post('http://apitraining.vipawaworks.com/api/course/take_course/' + id).then(response => {
        response.data
        this.$router.push({ name: 'CourseLearn', params: {id: id} })
      }).catch(errors => {
        this.errors = errors.response.data.errors
        if (errors.response.status === 401) {
          localStorage.removeItem('user_data')
          this.$router.push({ name: 'Login', query: { redirect: '/courses/'+ id } })
        }
      })
    },
    takQuiz(id){
      axios.post('http://apitraining.vipawaworks.com/api/quiz/take_quiz/' + id).then(response => {
        response.data
        this.$router.push({ name: 'CourseLearn', params: {id: id} })
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getSubscriptionStatus(){
      let id = this.$route.params.id
      axios.get('http://apitraining.vipawaworks.com/api/course/get_subscribed_status/' + id).then(response => {
        this.subscriptionStatus = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getCourse(){
      let id = this.$route.params.id
      axios.get('http://apitraining.vipawaworks.com/api/course/show/' + id).then(response => {
        this.course = response.data
        document.title = this.course.title +' - Training'
      }).catch(errors => {
        this.errors = errors.response.data.errors
      }).finally(() => {
        setTimeout(function () {
          this.isLoading =  false
        }.bind(this), 500);
      })
    },
    getCourseContent(){
      let id = this.$route.params.id
      axios.get('http://apitraining.vipawaworks.com/api/course_content/get_contents_with_lessons_quizzes/' + id).then(response => {
          this.course_content = response.data
      }).catch(errors => {
          this.errors = errors.response.data.errors
      }).finally(() => {
          setTimeout(function () {
              this.isLoading =  false
          }.bind(this), 500);
      })
    },
    getCourseTest(){
      let id = this.$route.params.id
      axios.get('http://apitraining.vipawaworks.com/api/test/get_test_with_questions/'+ id).then(response => {
          this.tests = response.data
      }).catch(errors => {
          this.errors = errors.response.data.errors
      })
    },
  },
  created(){
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getCourse() 
    this.getCourseContent()
    this.getSubscriptionStatus()
    this.getCourseTest()
    this.moment = moment
  }
}
</script>

<style>
.image{
  height: 200px;
  width: 300px;
}
</style>